<template>
  <v-container fluid>
    <v-row>
      <v-col md="6" cols="12">
        <div class="conOfHeader">
          <div class="headerOfPage">My Attendance</div>
        </div>
      </v-col>
    </v-row>
    <div class="d-flex justify-space-between mb-5">
      <form class="conFormSubmit conSearchForm mt-5">
        <v-row>
          <v-col cols="12" md="4">
            <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  v-model="search.start_date"
                  label="From"
                  persistent-hint
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="search.start_date"
                no-title
                @input="menu1 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="4">
            <v-menu
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  v-model="search.end_date"
                  label="To"
                  persistent-hint
                  prepend-icon="mdi-calendar"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="search.end_date"
                no-title
                @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col md="4" cols="12" class="d-flex">
            <div class="conOfHeader text-right">
              <div class="btnAdd">
                <v-btn @click="getListData()" class="btnAddPrimary">
                  Search
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </form>
    </div>
    <v-row>
      <v-col cols="12">
        <section class="conOfProjects">
          <div class="conOfTable">
            <v-data-table
              :headers="headers"
              :items="listData"
              hide-default-footer
            >
            </v-data-table>
          </div>
        </section>
        <div class="conOfPagination pr-5 pt-5">
          <v-pagination
            v-model="pagination.current_page"
            :total-visible="7"
            :length="pagination.last_page"
            @input="goToPage(pagination.current_page)"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ApiService } from "@/modules/shared/services/api";
let apiServices = new ApiService();

export default {
  data: () => ({
    pagination: {
      current_page: 1,
    },
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    secDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),

    menu1: false,
    menu2: false,
    search: {
      start_date: "",
      end_date: "",
    },
    headers: [
      { text: "project", value: "project.project_name" },

      { text: "Sign In", value: "project.created_at" },

      { text: "Sign Out", value: "project.updated_at" },
    ],
    listData: [],
  }),
  computed: {},
  methods: {
    goToPage(page) {
      this.getListData(page);
      window.scrollTo(0, 0);
    },
    getListData(page) {
      this.listData = [];
      apiServices
        .get(
          `my-attendances?per_page=7&page=${page}&start_date=${this.search.start_date}&end_date=${this.search.end_date}`
        )
        .then((res) => {
          if (res) {
            if (res.data.length > 0) {
              this.isLoadingSearch = false;
              this.listData = res.data;
              console.log("ListData", this.listData);
              this.pagination = res.meta;
              console.log("pagination", this.pagination);
            }
            this.isLoading = false;
          } else this.isLoading = false;
        });
    },
  },
  created() {
    this.getListData(this.pagination.current_page);
  },
  watch: {},
};
</script>
<style lang="scss" scoped>
@import "./_myattendance.scss";
</style>
